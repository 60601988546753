<template>
    <div class="container">
        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex;">
                <el-input
                    v-model="keyword"
                    style="width: 250px;"
                    placeholder="请输入学校名称"
                >
                </el-input>
                
                <el-button type="primary" style="margin-left: 20px;" @click="getTableList">查询</el-button>
            </div>
            <!-- <div>
                <el-button type="primary" style="margin-left: 20px;">导出报名表</el-button>
            </div> -->
        </div>
        <!-- table -->
        <div style="margin-top: 20px;">
            <el-table :data="tableList" v-loading="dataListLoading"  style="width: 1490px;" height="600">
                <el-table-column label="序号" width="50" type="index" header-align="center" align="center" fixed="left"></el-table-column>
                <el-table-column v-for="(item, key) in columns" :key="key" :width="item.width || 'auto'" align="center" :label="item.label">
                    <template slot-scope="scope">
                        <div v-if="!item.slot">{{scope.row[item.prop]}}</div>
                        <div v-else>
                            <div v-if="item.prop === 'applyUrl'">
                                <a :href="scope.row[item.prop]" target="_blank">点击查看</a>
                            </div>
                            <div v-if="item.prop === 'auditStatus'">
                                <el-tag :type="scope.row[item.prop] === 1 ? 'success' : scope.row[item.prop] === 2 ? 'error' : '' ">
                                    {{ scope.row[item.prop] === 1 ? '通过' : scope.row[item.prop] === 2 ? '未通过' : '待审核' }}
                                </el-tag>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="学校论文" header-align="center" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button 
                            v-if="scope.row.reportUrl" 
                            type="text" 
                            size="small" 
                            
                            :disabled="userId == 4 ? false : scope.row.userId !== userId ? true : false" 
                            @click="handleClick(scope.row,1)"
                        >
                            查看论文
                        </el-button>
                        <el-upload
                            ref="fileUploadBtn"
                            class="upload-demo"
                            :show-file-list="false"
                            :action="action"
                            accept=".pdf,.doc,.docx"
                            multiple
                            :limit="1"
                            :before-upload="handleBefore"
                            :on-success="hanleSuccess"
                        >
                            <el-button  
                                type="text" 
                                size="small" 
                                :disabled="userId == 4 ? false : scope.row.userId !== userId ? true : false" 
                                @click="handleClick(scope.row,2)">上传论文</el-button>
                        </el-upload>
                    </template>
                </el-table-column>
                <el-table-column label="操作" header-align="center" align="center" fixed="right" v-if="type === 9">
                    <template slot-scope="scope">
                        <el-button 
                            type="text" 
                            size="small" 
                            @click="goShenhe(scope.row)"
                            :disabled="scope.row.auditStatus === 1"
                        >
                            审核
                        </el-button>
                    </template>
                </el-table-column>
                
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <el-pagination
                layout="prev, pager, next"
                :total="total"
                @current-change="currentChange"
                @prev-click="prevClick"
                @next-click="nextClick"
            >
            </el-pagination>
        </div>
        <auditVue ref="audit"/>
    </div>
</template>

<script>
import { $activity } from "@/services";
import $cookies from "vue-cookies";
import {BASE} from '@/services/base'
import auditVue from './components/audit.vue';
export default {
    components: {
        auditVue
    },
    data() {
        return {
            columns:[
                { prop: 'schoolName', label: '学校名称',width:'200' }, 
                { prop: 'province', label: '省份'},
                { prop: 'address', label: '详细地址',width:'200' },
                { prop: 'teacher1', label: '指导老师1'},
                { prop: 'teacher1Contact', label: '电话'},
                { prop: 'teacher2', label: '指导老师2'},
                { prop: 'teacher2Contact', label: '电话'},
                { prop: 'studentName', label: '学生'},
                { prop: 'applyUrl', label: '报名表',slot:true},
                { prop: 'auditStatus', label: '审核状态',slot:true},
                { prop: 'auditOpinion', label: '审核意见'},
                { prop: 'remark', label: '备注'},
                { prop: 'action', label: '操作'},
            ],
            school:'',
            dataListLoading:false,
            value1:'',
            tableList:[],
            pageNum:1,
            pageSize:10,
            keyword:'',
            total:0,
            userId:$cookies.get("userInfo").id,
            type:$cookies.get("userInfo").type,
            action: BASE + '/server/common/upload',
            id:'',
        }
    },
    created() {
        this.getTableList()
    },
    methods:{
        async getTableList() {
            if (!this.dataListLoading) {
                this.dataListLoading = true
                let params = {}
                params.pageNum = this.pageNum
                params.pageSize = this.pageSize
                params.keyword = this.keyword
                params.days = ''
                params.school = ''
                params.address = ''
                params.userId = $cookies.get("userInfo").id
                let res = await $activity.signList(params)
                if (res.code === 200) {
                    this.total = res.data.total
                    this.tableList = res.data.records
                }
                this.dataListLoading = false
            }
        },
        currentChange(e) { 
            this.pageNum = e
            this.getTableList()
        },
        prevClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        nextClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        async handleBefore(file) {
            if (file.size / 1024 / 1024 > 5) {
                this.$message.error('上传论文不能大于5M')
                this.fileList = []
                return false
            }
        },
        hanleSuccess(response) { 
            if(response.code === 200) {
                let params = {}
                params.id = this.id
                params.reportUrl = response.data.url
                $activity.reportLunwen(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('上传成功')
                        this.$refs.fileUploadBtn.clearFiles();
                        this.getTableList()
                    } else {
                        this.$message.error('系统错误,请联系管理员')
                        this.$refs.fileUploadBtn.clearFiles();
                    }
                }).catch(() => {
                    this.$refs.fileUploadBtn.clearFiles();
                    this.$message.error('系统错误,请联系管理员')
                })
            }
        },
        async handleClick(row,index) { 
            let params = {}
            params.pageNum = 1
            params.pageSize = 999
            params.date = ''
            params.userId = row.userId
            // let res = await $activity.reportList(params)
            // if (res.data.records.length < 15) { 
            //     this.isreport = false
            // } else {
            //     this.isreport = true
            // }
            if (index === 1) {
                window.open(row.reportUrl, '_blank')
            } else {
                this.id = row.id
            }
        },
        goShenhe(row) {
            this.$refs.audit.setFlag(row)
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    padding: 40px;
}
</style>